import React, { createContext, useReducer } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

// Takes a hex code and returns lighter (positive amt) or darker (negative amt) hex
const adjustHex = (hex, amt) => {
  let pound = false;
  if (hex[0] === '#') {
    hex = hex.slice(1);
    pound = true;
  }
  let rgb = [
    parseInt(hex.substring(0, 2), 16),
    parseInt(hex.substring(2, 4), 16),
    parseInt(hex.substring(4, 6), 16)
  ];

  rgb = rgb.map(num => {
    num = num + amt;
    if (num > 255) {
      num = 255;
    } else if (num < 0) {
      num = 0;
    }
    const str = num.toString(16);
    return str.length === 1 ? `0${str}` : str;
  });

  return `${pound && '#'}${rgb.join('')}`;
};

export const initialThemeState = {
  color: '#59b0a1',
  colorShade: adjustHex('#59b0a1', -20),
  logoUrl:
    'https://storage.googleapis.com/assessments-server-assets-prod/prod/logos/proctoring/tenant-logo-placeholder.png',
  c1LogoUrl:
    'https://storage.googleapis.com/assessments-server-assets-prod/prod/logos/proctoring/tenant-logo-c1.png',
  showNavLogo: false,
  fontFamily: 'AvenirLTPro-Roman',
  boldFontFamily: 'AvenirLTPro-Heavy',
  blackFontFamily: 'AvenirLTPro-Black',
  mediumFontFamily: 'AvenirLTPro-Medium',
  condensedFontFamily: 'AvenirNextLTPro-BoldCn',
  altFontFamily: '"Roboto", sans-serif',
  black: '#000000',
  white: '#ffffff'
};

export const ThemeContext = createContext(initialThemeState);

export const SET_ASSESSMENT_THEME = 'SET_ASSESSMENT_THEME';

const themeReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ASSESSMENT_THEME:
      return {
        ...state,
        color: payload.default_theme_color,
        colorShade: adjustHex(payload.default_theme_color, -20),
        logoUrl: payload.logo_url,
        showNavLogo: payload.show_nav_logo
      };
    default:
      return state;
  }
};

const ThemeProvider = ({ children }) => {
  const [themeState, themeDispatch] = useReducer(
    themeReducer,
    initialThemeState
  );

  return (
    <ThemeContext.Provider value={{ themeState, themeDispatch }}>
      <StyledThemeProvider theme={themeState}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
