import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import ClickableBottomNavBar from 'components/local/navbar/ClickableBottomNavBar';
import Modal from 'components/local/modals/Modal';
import PageLoading from 'components/local/loading/PageLoading';
import ProgressBarClickable from 'components/global/progressBar/ProgressBarClickable';
import SectionWrapper from 'containers/inProgressPage/subcomponents/SectionWrapper';
import Slot from 'containers/inProgressPage/subcomponents/Slot';
import { beforeUnload } from 'containers/inProgressPage/helpers/beforeUnload';
import { formatAnswer } from 'containers/inProgressPage/helpers/formatAnswer';
import { moveToNextSlot } from 'services/requests/helpers/moveToNextSlot';
import { reconcileAnswer } from 'containers/inProgressPage/helpers/reconcileAnswer';
import { slotTypeOptions } from 'containers/app/helpers/slotTypeOptions';
import { useGlobalEventListener } from 'components/global/hooks/useGlobalEventListener';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { useHandleSlot } from 'containers/inProgressPage/hooks/useHandleSlot';
import { useHandleSlotRequests } from 'containers/inProgressPage/hooks/useHandleSlotRequests';
import { validateForm } from 'containers/inProgressPage/helpers/validateForm';
import { SET_ERROR } from 'services/context/assessmentContext/assessmentContext';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { StyledErrorMessage } from '../linear/SectionLinear';
import { MAX_FILE_SIZE } from 'utils/constants/constants.js';

const SectionClickable = () => {
  const {
    state,
    dispatch,
    currentSection,
    currentSlot
  } = useAssessmentContext();
  const {
    assessmentAttempt,
    currentSlotId,
    currentSlotIndex,
    currentSectionIndex,
    error,
    slotIsLoading
  } = state;
  const [newSlot, setNewSlot] = useState(currentSlotIndex);
  const [fileError, setFileError] = useState(null);
  const { handleSubmitClickableSlot } = useHandleRequest();
  const {
    banded,
    select,
    upload,
    modal,
    setModal,
    submitSection,
    setSubmitSection,
    answer,
    questionType
  } = useHandleSlot(currentSlot);
  const formikProps = useFormik({
    initialValues: { [currentSlotId]: banded || upload ? null : [] },
    validationSchema: validateForm(currentSlotId, questionType, currentSlot),
    onSubmit: values => handleSwitchSlot(values)
  });
  const {
    state: { files }
  } = useAssessmentContext();

  useGlobalEventListener('beforeunload', beforeUnload, window);
  useHandleSlotRequests();

  const setInitialValues = () => {
    dispatch({
      type: 'SET_FILE',
      payload: {}
    });
    if (select && answer) {
      reconcileAnswer(formikProps, currentSlot, currentSlotId, answer);
    } else if (banded) {
      formikProps.setValues({
        [currentSlotId]: answer?.answer_value.toString() || null
      });
    } else {
      formikProps.setValues({ [currentSlotId]: [] });
    }
  };

  useEffect(() => {
    if (currentSlot?.slot_type && formikProps) {
      setInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlot]);

  useEffect(() => {
    if (error) {
      dispatch({
        type: SET_ERROR,
        payload: null
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlotId]);

  useEffect(() => {
    if (submitSection) {
      formikProps.errors[currentSlotId]
        ? handleSubmitClickableSlot(null, null, submitSection)
        : formikProps.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitSection]);

  const handleNextSection = () => {
    setNewSlot(null);
    setSubmitSection(true);
  };

  const handleSwitchSlot = async values => {
    /* NOTE: this logic only supports static, question_select, File,  and
    question_banded. As support for new slot/question types are added,
    this will need to be reevaluated. */
    const value = values[currentSlotId];
    // If slot is static, always submit
    if (currentSlot.slot_type === slotTypeOptions.static) {
      handleSubmitClickableSlot(newSlot, null, submitSection);
      /* If the value indicates a blank input, do not submit unless the section
    has timed out */
    } else if (value === null || value === undefined || value.length === 0) {
      if (submitSection) {
        handleSubmitClickableSlot(null, value, submitSection);
      } else if (newSlot !== null) {
        moveToNextSlot(
          newSlot,
          currentSectionIndex,
          assessmentAttempt,
          dispatch
        );
      }
      /* If the value indicates an answer is inputted (non empty string, file or array),
    format answer as necessary and submit */
      // Or if its a file also submit it as is
    } else if (value.length || value instanceof File) {
      handleNextSlot(value);
    }
  };

  const handleNextSlot = async value => {
    const fileKey = Object.keys(files)[0];
    const fileValue = files[fileKey] ?? null;

    if (fileValue instanceof File && fileValue.size > MAX_FILE_SIZE) {
      let sizeInMB = (fileValue.size / (1024 * 1024)).toFixed(2);
      const errorMessage = `There is an error with your file. File size exceeds 30MB, current value is ${sizeInMB} MB`;
      setFileError(errorMessage);
      return;
    }

    setFileError(null);

    const answer = await formatAnswer(
      fileValue instanceof File ? fileValue : value,
      currentSlot,
      select,
      banded
    );
    if (answer instanceof Error) {
      setFileError('There was an error uploading the file');
      return;
    }
    handleSubmitClickableSlot(newSlot, answer, submitSection);
  };

  if (slotIsLoading || !currentSlotId) return <PageLoading />;
  const finalSortIndex = Math.max(
    ...assessmentAttempt.assessment.sections.map(s => s.sort_index)
  );
  const modalType =
    !currentSection.is_linear && currentSection.sort_index === finalSortIndex
      ? 'submitAssessment'
      : 'submitSection';
  return (
    <SectionWrapper
      formikProps={formikProps}
      setNewSlot={setNewSlot}
      setSubmitSection={setSubmitSection}
    >
      <form onSubmit={formikProps.handleSubmit}>
        {modal && (
          <Modal
            handleAccept={handleNextSection}
            handleClose={() => setModal(false)}
            modalType={modalType}
          />
        )}
        <ProgressBarClickable
          currentSection={currentSection}
          currentSlotIndex={currentSlotIndex}
          disabled={false}
          formikProps={formikProps}
          setSlot={setNewSlot}
        />
        <Slot formikProps={formikProps} />
        <ClickableBottomNavBar
          formikProps={formikProps}
          setSlot={setNewSlot}
          setModal={setModal}
        />
      </form>
      {fileError && <StyledErrorMessage>{fileError}</StyledErrorMessage>}
    </SectionWrapper>
  );
};

export default SectionClickable;
