import Modal from 'components/local/modals/Modal';
import React, { createContext, useReducer, useEffect, useState } from 'react';
import { useHotkeys } from './hooks/useHotkey';
import { useHasWindowFocus } from './hooks/useHasWindowFocus';
import { useIsCopying } from './hooks/useIsCopying';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { postFrontendEvent } from 'services/requests/eventRequests';
import {
  ASSESSMENT_POLICE_ACTIONS,
  ASSESSMENT_POLICE_EVENTS,
  screenCaptureHotkeys,
  screenRecordingHotkeys,
  DISABLE_WARNINGS
} from './constants';
import { QuestionTypes } from 'containers/app/helpers/questionTypes';
import { useRef } from 'react';

export const AssessmentPoliceContext = createContext();

const initialAssessmentPoliciesState = {
  isCopying: false,
  isChangingTabs: false
};

const policeReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ASSESSMENT_POLICE_ACTIONS.SET_IS_COPYING: {
      return {
        ...state,
        isCopying: payload.isCopying
      };
    }

    case ASSESSMENT_POLICE_ACTIONS.SET_IS_CHANGING_TABS: {
      return {
        ...state,
        isChangingTabs: payload.isChangingTabs
      };
    }

    case ASSESSMENT_POLICE_ACTIONS.SET_BROWSER_CAPTURE: {
      return {
        ...state,
        isBrowserCapture: payload.isBrowserCapture
      };
    }

    default: {
      return state;
    }
  }
};

const logDetectionEvent = async (
  assessment_attempt_uuid,
  event_name,
  question_id,
  additional_info
) => {
  // Sends an event to the event log on the backend
  postFrontendEvent(
    assessment_attempt_uuid,
    event_name,
    question_id,
    additional_info
  );
};

const onDetect = (status, dispatcher) => {
  /**
   * dev-note: when a test taker enter the first time in the assessment page and the api status is 'IN_PROGRESS'
   * actually, the react provider is not yet loaded, so the dispatcher will be still be 'PENDING'
   * instead we're also gonna rely on the current route as a last resort.
   */
  const isUrlInProgress = window.location.href.includes('in-progress');
  if (status === 'IN_PROGRESS' || isUrlInProgress) dispatcher();
};

export const AssessmentPoliceProvider = ({ children }) => {
  const {
    state: assessmentState,
    currentSlot,
    disableScreenWarnings
  } = useAssessmentContext();
  const previousQuestionType = useRef(null);
  const [state, dispatch] = useReducer(
    policeReducer,
    initialAssessmentPoliciesState
  );
  const [logQueue, setLogQueue] = useState([]);
  const assessmentStatus =
    assessmentState.assessmentAttempt?.attempt_state || 'PENDING';

  useEffect(() => {
    /*
    I turned the event log into a queue because 
    the currentSection is needed to be loaded 
    when to log the question_id
    */
    if (assessmentState.assessmentIsLoading) return;
    if (assessmentState.slotContentIsLoading) return;
    if (currentSlot === undefined) return;
    if (currentSlot.hasOwnProperty('question') === false) return;
    if (logQueue.length > 0) {
      logQueue.forEach(async event => {
        const uuid = assessmentState.assessmentAttempt?.uuid;
        const question_id = currentSlot.question.id;
        const additional_info = '';
        await logDetectionEvent(uuid, event, question_id, additional_info);
      });
      setLogQueue([]);
    }
  }, [assessmentState, logQueue, currentSlot]);

  useEffect(() => {
    if (
      currentSlot &&
      currentSlot.question &&
      currentSlot?.question?.question_type
    ) {
      previousQuestionType.current = currentSlot.question.question_type;
    }
  }, [currentSlot]);

  const onFocus = () => {
    if (disableScreenWarnings?.includes(DISABLE_WARNINGS.SWITCH_TABS)) return;
    if (
      currentSlot?.question?.question_type !== QuestionTypes.UPLOAD &&
      previousQuestionType.current !== QuestionTypes.UPLOAD
    ) {
      setLogQueue(pq => [...pq, ASSESSMENT_POLICE_EVENTS.TAB_CHANGE_ON_FOCUS]);
      onDetect(assessmentStatus, () =>
        dispatch({
          type: ASSESSMENT_POLICE_ACTIONS.SET_IS_CHANGING_TABS,
          payload: { isChangingTabs: true }
        })
      );
    }
  };
  const onBlur = () => {
    if (disableScreenWarnings?.includes(DISABLE_WARNINGS.SWITCH_TABS)) return;
    if (
      currentSlot?.question?.question_type !== QuestionTypes.UPLOAD &&
      previousQuestionType.current !== QuestionTypes.UPLOAD
    ) {
      onDetect(assessmentStatus, () =>
        dispatch({
          type: ASSESSMENT_POLICE_ACTIONS.SET_IS_CHANGING_TABS,
          payload: { isChangingTabs: false }
        })
      );
    }
  };
  const onCopy = () => {
    if (disableScreenWarnings?.includes(DISABLE_WARNINGS.COPY_PASTE)) return;
    setLogQueue(pq => [...pq, ASSESSMENT_POLICE_EVENTS.COPY_ATTEMPT]);
    onDetect(assessmentStatus, () =>
      dispatch({
        type: ASSESSMENT_POLICE_ACTIONS.SET_IS_COPYING,
        payload: { isCopying: true }
      })
    );
  };
  const onWebCapture = () => {
    if (disableScreenWarnings?.includes(DISABLE_WARNINGS.SCREENSHOT)) return;
    setLogQueue(pq => [
      ...pq,
      ASSESSMENT_POLICE_EVENTS.BROWSER_CAPTURE_ATTEMPT
    ]);
    onDetect(assessmentStatus, () =>
      dispatch({
        type: ASSESSMENT_POLICE_ACTIONS.SET_BROWSER_CAPTURE,
        payload: { isBrowserCapture: true }
      })
    );
  };

  useIsCopying(onCopy);
  useHasWindowFocus(onFocus, onBlur);

  useHotkeys(
    [...screenCaptureHotkeys, ...screenRecordingHotkeys].map(hk => [
      hk,
      onWebCapture
    ])
  );

  return (
    <AssessmentPoliceContext.Provider value={{ state, dispatch }}>
      {state.isCopying && (
        <Modal
          handleAccept={() =>
            dispatch({ type: 'SET_IS_COPYING', payload: { isCopying: false } })
          }
          handleClose={() =>
            dispatch({ type: 'SET_IS_COPYING', payload: { isCopying: false } })
          }
          modalType="copyDetected"
        />
      )}

      {state.isChangingTabs &&
        currentSlot?.question?.question_type !== QuestionTypes.UPLOAD &&
        previousQuestionType.current !== QuestionTypes.UPLOAD && (
          <Modal
            handleAccept={() =>
              dispatch({
                type: 'SET_IS_CHANGING_TABS',
                payload: { isChangingTabs: false }
              })
            }
            handleClose={() =>
              dispatch({
                type: 'SET_IS_CHANGING_TABS',
                payload: { isChangingTabs: false }
              })
            }
            modalType="tabChangeDetected"
          />
        )}

      {state.isBrowserCapture && (
        <Modal
          handleAccept={() =>
            dispatch({
              type: 'SET_BROWSER_CAPTURE',
              payload: { isBrowserCapture: false }
            })
          }
          handleClose={() =>
            dispatch({
              type: 'SET_BROWSER_CAPTURE',
              payload: { isBrowserCapture: false }
            })
          }
          modalType="browserCaptureDetected"
        />
      )}
      {children}
    </AssessmentPoliceContext.Provider>
  );
};
