import { useContext } from 'react';
import { AssessmentContext } from './assessmentContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useAssessmentContext = () => {
  const { state, dispatch } = useContext(AssessmentContext);
  const { assessmentAttempt, currentSectionIndex, currentSlotIndex } = state;
  const { temporaryApConfiguration } = useFlags();
  let currentSection;
  let currentSlot;
  let disableScreenWarnings =
    assessmentAttempt?.assessment?.configuration?.disable_screen_warnings;

  // We need to parse the array because its reaching our server as a string
  // e.g "['value','value']"
  // /'|\[|\]|\s+/g with this regex pattern, we can remove ' [ and ] in one replace
  if (typeof disableScreenWarnings === 'string' && temporaryApConfiguration) {
    try {
      disableScreenWarnings = disableScreenWarnings
        .replace(/'|\[|\]|\s+/g, '')
        .split(',');
    } catch (error) {
      console.error('Failed to parse disableScreenWarnings:', error);
      disableScreenWarnings = [];
    }
  } else if (!temporaryApConfiguration) {
    // If the feature flag is not enabled, we should not disable any warnings
    disableScreenWarnings = [];
  }

  if (Number.isInteger(currentSectionIndex)) {
    currentSection = assessmentAttempt?.section_attempts[currentSectionIndex];
  }

  if (Number.isInteger(currentSlotIndex)) {
    currentSlot = currentSection?.slot_attempts[currentSlotIndex];
  }

  return {
    state,
    dispatch,
    assessmentAttempt,
    currentSection,
    currentSlot,
    disableScreenWarnings
  };
};
